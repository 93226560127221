import * as React from 'react';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import { InsightPreview } from '../../types';
import NewsItem from './newsItem';

const Teaser = ({
  insights,
  black,
}: {
  insights: InsightPreview[];
  black?: boolean;
}) => (
  <div className="text-black flex flex-col items-center">
    <div className="text-black w-full max-w-[85rem]">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-[5rem]">
        {insights.map(({ slug, summary, tags, title, image }, index) => {
          if (index < 2) {
            return (
              <NewsItem
                key={title}
                title={title}
                summary={summary}
                slug={slug}
                tags={tags}
                image={image}
              />
            );
          }

          return null;
        })}
      </div>
      <div className="mt-[3.75rem]">
        <hr className="border-black hidden lg:block" />
        <div className="flex items-center justify-between pt-[1rem] pb-[1rem]">
          <div className="hidden lg:block">
            <h4 className="my-0">
              <Trans>Explore more</Trans>
            </h4>
          </div>
          <Link
            to="/insights"
            type="button"
            className={`main button text-center ${
              black ? 'black' : ''
            } w-full lg:w-auto font-semibold`}
          >
            <Trans>View all insights</Trans>
          </Link>
        </div>
        <hr className="border-black hidden lg:block" />
      </div>
    </div>
  </div>
);

export default Teaser;
