import * as React from 'react';

const Sides = ({
  type = 'content',
  children,
}: {
  type?: 'content' | 'wide';
  children: React.ReactNode;
}) => {
  if (type === 'wide') {
    return <div className="relative pr-[1.25rem] pl-[1.25rem]">{children}</div>;
  }
  return (
    <div className="flex justify-center">
      <div className="relative pr-[1.25rem] pl-[1.25rem] w-full md:mx-[5rem] max-w-[85rem]">
        {children}
      </div>
    </div>
  );
};

export default Sides;
