import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Base from '../components/base';
import { Insight, InsightPreview } from '../types';
import InsightComponent from '../components/insights/insight';
import Teaser from '../components/insights/teaser';
import { formatRawInsightPreview, formatRawInsight } from '../utilities';
import Sides from '../components/ranges/sides';
import SEO from '../components/seo';

const InsightDisplay = ({
  data: {
    markdownRemark: rawInsight,
    allMarkdownRemark: { nodes },
  },
  location,
}: PageProps<
  Queries.InsightBySlugQuery,
  { currentPage: number; total: number }
>) => {
  const insights: InsightPreview[] = nodes.map(formatRawInsightPreview);
  const insight: Insight = formatRawInsight(rawInsight);

  return (
    <Base location={location}>
      <div className="text-black">
        <div className="bg-background absolute -z-50 top-0 left-0 min-h-[20vh] min-w-[100vw] lg:hidden" />
        <Sides type="wide">
          <div className="bg-background absolute -z-50 top-0 left-0 h-full min-w-[100vw] lg:hidden" />
          <InsightComponent insight={insight} />
        </Sides>
        <div className="my-[5.625rem] lg:my-[8.75rem]">
          <Sides type="wide">
            <Teaser insights={insights} />
          </Sides>
        </div>
      </div>
    </Base>
  );
};

export default InsightDisplay;

export const pageQuery = graphql`
  query InsightBySlug($id: String!, $language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      html
      frontmatter {
        date(formatString: "DD.MM.YYYY")
        title
        alttext
        summary
        thumbnail {
          publicURL
        }
        tags {
          name
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fields: { sourceName: { eq: "insights" } }
        frontmatter: { draft: { eq: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD.MM.YYYY")
          title
          alttext
          summary
          thumbnail {
            publicURL
          }
          tags {
            name
          }
        }
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO title="CR14 Insight" />;
