import * as React from 'react';

const Tags = ({ tags }: { tags: string[] }) => {
  return (
    <div className="flex gap-4 items-center h-full w-full overflow-x-auto">
      {tags.map((tag) => (
        <button key={tag} type="button" className="tag">
          {tag}
        </button>
      ))}
    </div>
  );
};

export default Tags;
