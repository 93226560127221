import * as React from 'react';

const InsightImage = ({
  image,
}: {
  image?: { src: string; altText: string };
}) => {
  return image ? (
    <img
      className="aspect-video rounded-xl w-full"
      src={image.src}
      alt={image.altText}
    />
  ) : (
    <img
      className="aspect-video rounded-xl w-full"
      src="/images/ranges-desktop-landing.webp"
      alt="Placeholder"
    />
  );
};

export default InsightImage;
