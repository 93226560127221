import {
  Insight,
  InsightPreview,
  NatoEvent,
  RawDocument,
  RawEvent,
  RawInsight,
  RawInsightPreview,
  Document,
  RawCareer,
  Career,
  RawProject,
  Project,
} from './types';

export const range = (size: number, startAt = 0) =>
  [...Array(size).keys()].map((i) => i + startAt);

export const isNonNullable = <T>(value: T | undefined | null): value is T =>
  value !== null && value !== undefined;

export const formatRawInsightPreview = (
  rawInsightPreview: RawInsightPreview
): InsightPreview => ({
  title: rawInsightPreview.frontmatter?.title ?? 'No title',
  slug: rawInsightPreview.fields?.slug ?? 'no-slug',
  summary: rawInsightPreview.frontmatter?.summary ?? 'No summary',
  tags: (rawInsightPreview.frontmatter?.tags ?? [])
    .map((tag) => tag?.name)
    .filter(isNonNullable),
  image: rawInsightPreview.frontmatter?.thumbnail?.publicURL
    ? {
        src: rawInsightPreview.frontmatter?.thumbnail?.publicURL,
        altText: rawInsightPreview.frontmatter?.alttext ?? 'No alt text',
      }
    : undefined,
});

export const formatRawInsight = (rawInsight: RawInsight): Insight => ({
  ...formatRawInsightPreview(rawInsight as RawInsightPreview),
  html: rawInsight?.html ?? 'no content',
});

export const formatRawEvent = (rawEvent: RawEvent): NatoEvent => ({
  description: rawEvent.html ?? 'No description',
  title: rawEvent.frontmatter?.title ?? 'No title',
});

export const formatRawDocument =
  (language: string) =>
  (rawDocument: RawDocument): Document => ({
    title:
      (language === 'et'
        ? rawDocument.frontmatter?.titleEst
        : rawDocument.frontmatter?.title) ?? 'No title',
    link:
      rawDocument.frontmatter?.file?.publicURL ??
      rawDocument.frontmatter?.link ??
      'No link',
  });

export const formatRawCareer = (rawCareer: RawCareer): Career => ({
  title: rawCareer.frontmatter?.title ?? 'No title',
  content: rawCareer.html ?? 'No description',
  externalLink: rawCareer.frontmatter?.externalLink ?? undefined,
});

export const formatRawProject = (rawProject: RawProject): Project => ({
  title: rawProject.frontmatter?.title ?? 'No title',
  content: rawProject.html ?? 'No description',
  externalLink: rawProject.frontmatter?.externalLink ?? undefined,
  image: rawProject.frontmatter?.thumbnail?.publicURL
    ? {
        src: rawProject.frontmatter?.thumbnail?.publicURL,
        altText: rawProject.frontmatter?.alttext ?? 'No alt text',
      }
    : undefined,
  // @ts-ignore
  iconLinks: rawProject.frontmatter?.iconLinks
    ? rawProject.frontmatter?.iconLinks.map((icon) => icon?.publicURL)
    : undefined,
});

export const onlyUnique = <T>(value: T, index: number, self: Array<T>) =>
  self.indexOf(value) === index;
