import * as React from 'react';
import { Insight } from '../../types';
import Tags from './tags';
import InsightImage from './insightImage';

const InsightComponent = ({ insight }: { insight: Insight }) => (
  <div className=" bg-background flex flex-col items-center pt-[5rem] pb-[8.75rem]">
    <div className="max-w-[53.75rem]">
      <div>
        <Tags tags={insight.tags} />
      </div>
      <div className="mt-[1.25rem]">
        <h1>{insight.title}</h1>
        <p className="mt-[1.875rem]">{insight.summary}</p>
        <div className="mt-[3.75rem]">
          <InsightImage image={insight.image} />
        </div>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: insight.html }}
          className="mt-[3.75rem] insight-content"
        />
      </div>
    </div>
  </div>
);

export default InsightComponent;
