import * as React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import Tags from './tags';
import { InsightImage } from '../../types';
import InsightImageComponent from './insightImage';

const NewsItem = ({
  title,
  summary,
  slug,
  image,
  large = false,
  tags = [],
}: {
  title: string;
  summary: string;
  slug: string;
  image?: InsightImage;
  large?: boolean;
  tags?: string[];
}) => (
  <div className="text-black">
    <div
      className={`${
        large ? 'lg:flex hidden' : 'hidden'
      } flex lg:gap-[5rem] basis-1/3`}
    >
      <div className="flex gap-4 flex-col basis-1/3 items-start">
        <div>
          <Tags tags={tags} />
        </div>
        <h3>{title}</h3>
        <p>{summary}</p>
        <Link
          to={`/insights${slug}`}
          className="button text-black font-semibold"
        >
          Read more
        </Link>
      </div>
      <div className="flex flex-col basis-2/3">
        <InsightImageComponent image={image} />
      </div>
    </div>
    <Link
      className={`${
        large ? 'hidden' : 'lg:inline hidden'
      }  flex basis-1/3 no-underline text-black`}
      to={`/insights${slug}`}
    >
      <div className="flex flex-col">
        <InsightImageComponent image={image} />
        <div className="mt-[1.5rem]">
          <Tags tags={tags} />
        </div>
        <h4 className="mt-[1.5rem]">{title}</h4>
        <p className="mt-[1.5rem]">{summary}</p>
      </div>
    </Link>
    <Link
      to={`/insights${slug}`}
      className="flex flex-col gap-4 lg:hidden no-underline text-black"
    >
      <InsightImageComponent image={image} />
      <div>
        <Tags tags={tags} />
      </div>
      <h3>{title}</h3>
      <p>{summary}</p>
    </Link>
  </div>
);

export default NewsItem;
